import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitMobile_no } from "../store/auth/requestNumberSlice";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import { useForm } from "react-hook-form";

import { authenticationThunk } from "../store/auth/authenticationSlice";
import { showSnackbar } from "../store/uiSlice";
import { resendOtpThunk } from "../store/thunk/resendOtpThunk";

export const VerifyOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile_no = useSelector((state) => state.auth);

  let storedMobileNo = localStorage.getItem("mobile_no");

  React.useEffect(() => {
    if (mobile_no && !storedMobileNo) {
      localStorage.setItem("mobile_no", mobile_no);
      storedMobileNo = mobile_no;
    }
  }, [mobile_no]);

  React.useEffect(() => {
    if (!mobile_no && storedMobileNo) {
      dispatch(submitMobile_no({ mobile_no: storedMobileNo }));
    }
  }, [dispatch, mobile_no, storedMobileNo]);

  // Update local storage when 'mobile_no' changes

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      mobile_no: storedMobileNo,
      otp: "",
    },
  });

  const otpLength = 4;
  const watchOtp = watch("otp")

  const changeNumberHandler = () => {
    localStorage.removeItem("mobile_no");
    navigate("/");
  };

  const handleNumericInput = (event) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = value.replace(/\D/g, "");
    }
  };
  const resendOtpHandler = async () => {
    try {
      await dispatch(resendOtpThunk());
      // You can add any necessary logic after the number change here
    } catch (error) {
      // Handle the error if needed
      console.error("Error in changing number:", error);
    }
  };
  const onSubmit = async (data) => {
    try {
      await dispatch(authenticationThunk(data.mobile_no, data.otp));
      navigate("/home");
    } catch (error) {
      // Show the error message to the user or handle it appropriately
      dispatch(showSnackbar({ message: "Otp Invalid", severity: "error" }));
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            rowGap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="mobile_no"
            label="Mobile Number"
            type="number"
            variant="outlined"
            disabled
            {...register("mobile_no", {
              message: "Mobile Number is required",
            })}
            error={!!errors.mobile_no}
            helperText={errors.mobile_no?.message}
            onInput={handleNumericInput}
          />
          <Link
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={changeNumberHandler}
          >
            Change Number
          </Link>

          <TextField
            id="otp"
            label="OTP"
            type="number"
            autoComplete="current-password"
            variant="outlined"
            {...register("otp", {
              required: "OTP is required",
              minLength: {
                value: otpLength,
                message: `OTP should be of ${otpLength} digits`,
              },
              maxLength: {
                value: otpLength,
                message: `OTP should be of ${otpLength} digits`,
              },
            })}
            error={!!errors.otp}
            helperText={errors.otp?.message}
            onInput={handleNumericInput}
          />

          <Button
            variant="contained"
            sx={{ width: "120px" }}
            type="submit"
            disabled={watchOtp?.length !== otpLength}
          >
            verify OTP
          </Button>
          <Link
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={resendOtpHandler}
          >
            Resend OTP
          </Link>
        </Box>
      </form>
    </Box>
  );
};
