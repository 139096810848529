import React from "react";
import { motion } from "framer-motion";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";

export const PhoneVibration = () => {
  return (
    <motion.div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transformOrigin: "left center", // Set the transform origin to the left
      }}
      initial={{ rotate: -90, x: -50 }} // Initial position
      animate={{
        rotate: 0, // Rotate to upright position
        x: 0, // Move back to the center
        transition: { duration: 1.5, ease: "easeInOut" },
      }}
    >
      <SmartphoneOutlinedIcon  />
    </motion.div>
  );
};
