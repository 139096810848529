


export const otpUrl = 'https://dev.backend.mobigarage.com/v1/dgtemp/send_otp';
export const otpVerify ='https://dev.backend.mobigarage.com/v1/dgtemp/verify_otp';
export const otpResend = 'https://dev.backend.mobigarage.com/v1/dgtemp/resend_otp';


// export const timeLine = 


const baseUrl = 'https://dev.backend.mobigarage.com/';
const version = 'v1/';
const endPoint = 'dgtemp/send_otp';



// 1.  location
// 2. qc_request_list
// 3. qc_request