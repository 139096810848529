import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAuthToken } from "../../utils/auth";

function useGetProducts(cityId, requestId, brand, config, model) {
  const token = getAuthToken();

  return useQuery({
    queryKey: ["products", cityId, requestId, brand,model,config],
    queryFn: async () => {


      const brandQueryParam = brand ? `&brand=${brand.join(",")}` : "";
      const configQueryParam = config ? `&config=${config.join(",")}` : "";
      const modelQueryParam = model ? `&model=${model.join(",")}` : "";
      const response = await fetch(
        `https://dev.backend.mobigarage.com/v1/dgtemp/qc/qc_requests?city_id=${cityId}&request_id=${requestId}${brandQueryParam}${modelQueryParam}${configQueryParam}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to Fetch Data");
      }
      const responseData = await response.json();
      const { requests, end_date, total_count, filter_applied } = responseData.data;

      return { requests, end_date, total_count,filter_applied };
    },
    refetchOnWindowFocus: false,
    initialData: {
      requests: [],
      end_date: null,
      total_count: 0,
    },
  });
}

export const options = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

export default useGetProducts;
