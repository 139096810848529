import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header"


import { Box } from "@mui/material";



export const Home = () => {
  return (
    <Box sx={{display:'flex', flexDirection:'column'}}>
      <Header />
      <Box sx={{display:'flex', flexDirection:'column', p:2}}>
        <Outlet />
      </Box>
    </Box>
  );
};
