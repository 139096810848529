import { createSlice } from "@reduxjs/toolkit";
import { otpUrl } from "../../config";
import { showSnackbar } from "../uiSlice";

const initialState = {
  mobile_no: null,
};

const requestNumberSlice = createSlice({
  name: "requestNumber",
  initialState,
  reducers: {
    submitMobile_no: (state, action) => {
      state.mobile_no = action.payload.mobile_no;
    },
  },
});

export const numberVerificationThunk = (mobile_no) => {
  return async (dispatch) => {
    dispatch(
      showSnackbar({
        message: "Sending Request...",
      })
    );

    try {
      const response = await fetch(otpUrl, {
        method: "POST",
        body: JSON.stringify({ mobile_no }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        let errorMessage = "The provided mobile number is not registered";
        if (response.status === 404) {
          errorMessage = "The provided mobile number is not registered.";
        } else if (response.status === 500) {
          errorMessage = "Internal Server Error. Please try again later.";
        }
        dispatch(showSnackbar({ message: errorMessage, severity: "warning" }));
        return Promise.reject(errorMessage); // Reject the Promise on error
      }
      const data = await response.json();

      dispatch(submitMobile_no({ mobile_no }));

      localStorage.setItem("mobile_no", mobile_no);
      dispatch(
        showSnackbar({
          message: "OTP sent successfully.",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(showSnackbar({ message: "OTP Request Failed" }));
      return Promise.reject("Error submitting OTP");
    }
  };
};

export const { submitMobile_no } = requestNumberSlice.actions;
export default requestNumberSlice.reducer;
