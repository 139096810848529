import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { SpareParts } from "../../components/animation/SpareParts";
import { PhoneVibration } from "../../components/animation/PhoneVibration";

export const Homepage = () => {
  const navigate = useNavigate();
  const params = useParams;
  const city_id = params.city_id;

  const handlePhones = () => {
    navigate(`phones/${city_id}`);
  };
  const handleSpares = () => {
    navigate(`spares/${city_id}`);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: 12,
      }}
    >
      <Typography variant="h5">Please Select The category</Typography>
      <Stack spacing={6} justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          sx={{ display: "flex", columnGap: 1.6, padding:"42px" }}
          onClick={handlePhones}
        >
          <PhoneVibration />

          <Typography>Phones</Typography>
          <PhoneVibration />
        </Button>
        <Button
          variant="contained"
          sx={{ display: "flex", columnGap: 1, padding:"42px" }}
          onClick={handleSpares}
        >
          <SpareParts />
          <Typography>Spare Parts</Typography>
        </Button>
      </Stack>
    </Box>
  );
};
