import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAuthToken } from "../../utils/auth";

function useUpdateProduct() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (product) => {
      const token = getAuthToken();
      try {
        const postData = {
          id: product.id,
          description: product.description,
          demand: product.demand,
          price_for_original_screen: product.price_for_original_screen,
          price_for_copy_screen: product.price_for_copy_screen,
        };

        const response = await fetch(
          "https://dev.backend.mobigarage.com/v1/dgtemp/qc/qc_requests",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(postData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update product");
        }

        // If the response is successful, return the updated data
        return response.json();
      } catch (error) {
        // Handle any errors that occur during the fetch
        throw new Error("Failed to update product");
      }
    },
    onMutate: async (newProductInfo) => {
      // Get the previous products data from the cache
      const prevProducts = queryClient.getQueryData(["products"]);

      // Optimistically update the data in the query client
      queryClient.setQueryData(["products"], (prevProducts) => {
        // Check if prevProducts is an array before mapping
        return Array.isArray(prevProducts)
          ? prevProducts.map((prevProduct) =>
              prevProduct.id === newProductInfo.id ? newProductInfo : prevProduct
            )
          : prevProducts;
      });

      // Return the previous products data for possible rollback
      return { prevProducts };
    },
    onError: (error, newProductInfo, context) => {
      // Rollback to the previous products data on error
      if (context?.prevProducts) {
        queryClient.setQueryData(["products"], context.prevProducts);
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['products'] }), 
  });
}

export default useUpdateProduct;
