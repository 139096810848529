export const statusCheck = (obj) => {
  for (const key in obj) {
    // Replace null values with empty strings
    const value = obj[key] === null ? '' : obj[key];

    // Check if the value is an empty string
    if (value === '') {
      return true;
    }
  }
  return false;
};

