import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTerritory } from "../../store/locationSlice";
import MuiTable from "./DataTable";
import { fetchProfileData } from "../../store/profileDataSlice";
import { showSnackbar } from "../../store/uiSlice";

export const DashBoardSpares = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const territoryData = useSelector((state) => state.location.territoryData);
  const profileData = useSelector((state) => state.profileData.profileData);
  const [city, setCity] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const type = "spares"

  useEffect(() => {
    dispatch(fetchTerritory(type));
    dispatch(fetchProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (territoryData.length > 0 && !city) {
      const firstTerritoryId = territoryData[0].id;
      setCity(firstTerritoryId);
      navigate(`/home/spares/${firstTerritoryId}`, { replace: true });
    } else if (city) {
      navigate(`/home/spares/${city}`, { replace: true });
    }
  }, [territoryData, city, navigate]);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setCity(selectedId);

    if (selectedRequestId) {
      navigate(`/home/spares/${selectedId}/${selectedRequestId}`);
    } else {
      navigate(`/home/spares/${selectedId}`);
    }
  };

  const handleRowClick = (requestId) => {
    setSelectedRequestId(requestId);
  };

  const handlerPreviousPage = () => {
    navigate("/home");
  };

  const handleButtonClick = () => {
    if (city && selectedRequestId) {
      navigate(`/home/spares/${city}/${selectedRequestId}`);
    } else {
      dispatch(
        showSnackbar({
          message: "Select a report",
          severity: "warning",
        })
      );
    }
  };

  const memoizedTerritoryOptions = React.useMemo(
    () =>
      territoryData.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.label}
        </MenuItem>
      )),
    [territoryData]
  );


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 1,
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
          pb: 4,
          rowGap: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" color="text.secondary">
            Employee Name:
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {profileData.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1" color="text.secondary">
            Employee Code:
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {profileData.emp_id}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <FormControl
          sx={{
            width: {
              xs: "100%",
              sm: 250,
            },
          }}
        >
          <InputLabel id="demo-simple-select-autowidth-label">
            Select Territory
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={city}
            onChange={handleChange}
            autoWidth
            label="Select Territory"
          >
            {memoizedTerritoryOptions}
          </Select>
        </FormControl>
        <MuiTable city={city} onRowClick={handleRowClick} />
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" onClick={handlerPreviousPage}>
            Back
          </Button>

          <Button
            variant="contained"
            disabled={!selectedRequestId}
            onClick={handleButtonClick}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const loader =()=>{
  
}