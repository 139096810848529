/* eslint-disable react/jsx-pascal-case */
import { useEffect, useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";

import useGetProducts, {
  options,
} from "../../query/sparesQuery/useGetProducts";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { statusCheck } from "../../utils/status";
import useUpdateProduct from "../../query/sparesQuery/useUpdateProduct";
import { countDown } from "../../utils/timer";

import FiltersSpares from "./FiltersSpares";

const ProductsTableSpares = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSpare, setSelectedSpare] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const queryClient = useQueryClient();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const cityId = params.city_id;
  const requestId = params.request_id;

  const {
    data: products = [],
    isError: isLoadingProductsError,
    isFetching: isFetchingProducts,
    isLoading: isLoadingProducts,
  } = useGetProducts(
    cityId,
    requestId,
    selectedBrands,
    selectedSpare,
    selectedModels
  );

  useEffect(() => {
    return () => {
      document.body.classList.remove("filter-drawer-open");
    };
  }, []);

  const modifiedProducts = products.requests.map((product) => {
    const demand = product.demand === null ? "" : product.demand;

    const isPending = statusCheck({ ...product, demand });

    const status = isPending ? "pending" : "complete";

    return {
      ...product,
      demand,
      status,
    };
  });
  const getDefaultDemand = (demand) => {
    if (demand !== "") {
      return demand;
    }
    return "";
  };

  const handleDrawerOpen = () => {
    document.body.classList.add("filter-drawer-open");
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    document.body.classList.remove("filter-drawer-open");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 50,
        align: "center",
      },
      {
        accessorKey: "brand",
        header: "Brand",
        enableEditing: false,
        size: 50,
        align: "center",
      },
      {
        accessorKey: "model",
        header: "Model",
        enableEditing: false,
        size: 50,
        align: "center",
      },
      {
        accessorKey: "part_name",
        header: "Part Name",
        enableEditing: false,
        size: 50,
        align: "center",
      },
      // {
      //   accessorKey: "description",
      //   header: "Phone",
      //   enableEditing: false,
      //   size: 50,
      //   align: "left",
      //   muiEditTextFieldProps: {
      //     type: "text",

      //     required: true,
      //     error: !!validationErrors?.description,
      //     helperText: validationErrors?.description,
      //     onFocus: () =>
      //       setValidationErrors({
      //         ...validationErrors,
      //         description: undefined,
      //       }),
      //   },
      // },

      {
        accessorKey: "price_for_original",
        header: "Original Price (₹)",
        align: "center",
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 50,
        muiEditTextFieldProps: {
          type: "number",
          required: true,
          error: !!validationErrors?.price_for_original_screen,
          helperText: validationErrors?.price_for_original_screen,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              price_for_original_screen: undefined,
            }),
        },
      },
      {
        accessorKey: "price_for_copy",
        header: "Copy Price (₹)",
        align: "center",
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 50,

        muiEditTextFieldProps: {
          type: "number",
          required: true,
          error: !!validationErrors?.price_for_original_screen,
          helperText: validationErrors?.price_for_original_screen,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              price_for_copy_screen: undefined,
            }),
        },
      },
      {
        accessorKey: "demand",
        align: "center",
        muiTableHeadCellProps: {
          align: "center",
        },
        header: "Demand",
        size: 50,
        editVariant: "select",
        editSelectOptions: options,

        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.demand,
          helperText: validationErrors?.demand,
        },
        defaultValue: (row) => getDefaultDemand(row.original.demand),
      },

      {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
        size: 50,
        align: "right",
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === "pending"
                  ? theme.palette.error.dark
                  : theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              width: "10ch",
              p: "0.25rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            {cell.getValue() === "pending" ? "Pending" : "Complete"}
          </Box>
        ),
      },
    ],
    [validationErrors]
  );

  useEffect(() => {
    queryClient.invalidateQueries([
      "products",
      cityId,
      requestId,
      selectedBrands,
      selectedSpare,
      selectedModels,
    ]);
  }, [
    queryClient,
    cityId,
    requestId,
    selectedBrands,
    selectedSpare,
    selectedModels,
  ]);

  const { mutateAsync: updateProduct, isPending: isUpdatingProduct } =
    useUpdateProduct();

  const handleSaveUser = async ({ values, table }) => {
    const areFieldsEmpty = Object.values(values).some((value) => !value);

    if (areFieldsEmpty) {
      const newValidationErrors = {
        description: !values.description ? "Description is required" : "",
        price_for_original_screen: !values.price_for_original_screen
          ? "Original Price is Required"
          : "",
        price_for_copy_screen: !values.price_for_copy_screen
          ? "Copy Price is required"
          : "",
        demand: !values.demand ? "Demand is required" : "",
      };

      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    await updateProduct(values);
    table.setEditingRow(null);
  };

  const countPendingItems = (data) => {
    return data.reduce((count, item) => {
      if (item.status === "pending") {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const pendingItemsCount = countPendingItems(modifiedProducts);

  const table = useMaterialReactTable({
    columns,
    data: modifiedProducts,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableStickyHeader: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    positionToolbarAlertBanner: "top",
    enableMultiRowSelection: false,
    enableEditing: false,
    enableBottomToolbar: false,
    enableRowSelection: false,
    initialState: {
      columnVisibility: {
        id: false,
        price_for_original: false,
        price_for_copy: false,
        demand: false,
        brand: false,
      },
    },

    editDisplayMode: "modal",
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => table.setEditingRow(row),
      sx: {
        cursor: "pointer",
      },
    }),
    muiToolbarAlertBannerProps: isLoadingProductsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Update Product</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    state: {
      isLoading: isLoadingProducts,
      isSaving: isUpdatingProduct,
      showAlertBanner: isLoadingProductsError,
      showProgressBars: isFetchingProducts,
    },
  });
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    if (products?.end_date) {
      const intervalId = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = products.end_date - currentTime;

        if (remainingTime > 0) {
          setRemainingTime(remainingTime);
        } else {
          setRemainingTime(null);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [products?.end_date]);

  const formattedRemainingTime = countDown(remainingTime);
  const memoizedSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    const urlSelectedBrands = (memoizedSearchParams.get("brand") || "")
      .split(",")
      .filter(Boolean);
    const urlSelectedModels = (memoizedSearchParams.get("model") || "")
      .split(",")
      .filter(Boolean);
    const urlSelectedSpare = (memoizedSearchParams.get("partName") || "")
      .split(",")
      .filter(Boolean);

    // Set the state with the extracted values
    setSelectedBrands(urlSelectedBrands);
    setSelectedModels(urlSelectedModels);
    setSelectedSpare(urlSelectedSpare);
  }, [memoizedSearchParams]);

  const handleAppliedFilter = () => {
    const urlSelectedBrands = (searchParams.get("brand") || "")
      .split(",")
      .filter(Boolean);
    const urlSelectedModels = (searchParams.get("model") || "")
      .split(",")
      .filter(Boolean);
    const urlSelectedSpare = (searchParams.get("spare") || "")
      .split(",")
      .filter(Boolean);

    // Set the state with the extracted values
    setSelectedBrands(urlSelectedBrands);
    setSelectedModels(urlSelectedModels);
    setSelectedSpare(urlSelectedSpare);

    queryClient.invalidateQueries([
      "products",
      cityId,
      requestId,
      selectedBrands,
      selectedSpare,
      selectedModels,
    ]);
    console.log(selectedBrands);
  };
  const navigate = useNavigate();
  const handlerPreviousPage = () => {
    navigate(`/home/spares/${cityId}`);
  };
  const handlerHomePage = () => {
    navigate("../");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1">
          Count Down: {formattedRemainingTime}
        </Typography>
        <Typography variant="subtitle1">
          Remaining: {pendingItemsCount}
        </Typography>
      </Box>
      <Box display="flex" columnGap={1} alignItems="center">
        <Button
          variant="contained"
          color={!products.filter_applied ? "primary" : "success"}
          disableElevation
          sx={{ width: "fit-content" }}
          onClick={handleDrawerOpen}
        >
          {!products.filter_applied ? "Filter" : "Filter Applied"}
        </Button>
        <Box display="flex" columnGap={1}>
          {selectedBrands.length > 0 && (
            <Chip
              color="success"
              label="Brand"
              variant="outlined"
              // Add logic to clear the selected brands if needed
            />
          )}
          {selectedModels.length > 0 && (
            <Chip color="success" label="Model" variant="outlined" />
          )}
          {selectedSpare.length > 0 && (
            <Chip
              color="success"
              label="Spares"
              variant="outlined" // Add logic to clear the selected part name if needed
            />
          )}
        </Box>
      </Box>

      <FiltersSpares
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        onAppliedFilter={handleAppliedFilter}
      />

      <MaterialReactTable table={table} />
      <Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" onClick={handlerPreviousPage}>
            Back
          </Button>

          <Button variant="contained" onClick={handlerHomePage}>
            Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const queryClient = new QueryClient();

const ProductsSpares = () => (
  <QueryClientProvider client={queryClient}>
    <ProductsTableSpares />
  </QueryClientProvider>
);

export default ProductsSpares;
