import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";

import SideDrawer from "./SideDrawer";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfileData } from "../store/profileDataSlice";

export default function Header() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData.profileData);
  const authToken = useSelector(
    (state) => state.authentication.authData.authToken
  );

  React.useEffect(() => {
    if (authToken) {
      dispatch(fetchProfileData());
    }
  }, [dispatch, authToken]);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  React.useEffect(() => {
    return () => {
      document.body.classList.remove("filter-drawer-open");
    };
  }, []);

  const handleDrawerOpen = () => {
    document.body.classList.add("filter-drawer-open");
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    document.body.classList.remove("filter-drawer-open");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {authToken && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={handleDrawerOpen} // Opens the sidebar
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "block" } }}
          >
            MobiGarage
          </Typography>
          {authToken && (
            <>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex" } }}>
                <Typography variant="h6" gutterBottom component="h6">
                  {profileData.name}
                </Typography>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <SideDrawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        profileData={profileData}
      />{" "}
    </Box>
  );
}
