import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { clearAuthData } from "../store/auth/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../store/uiSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

export default function SideDrawer({ isOpen,
  onClose, profileData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("expiry_timestamp");
    localStorage.removeItem("mobile_no");
    dispatch(
      showSnackbar({
        message: "Logging Out",
        severity: "success",
      })
    );
    dispatch(clearAuthData());
    navigate("/");
  };
  const list = (
    <Box
      sx={{
        width: 250,
        p: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      role="presentation"
      onClick={onClose} // Close the drawer when an item is selected
      onKeyDown={onClose} // Support keyboard events for closing the drawer
    >
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ color: "#0174BE" }}
      >
        MobiGarage
      </Typography>
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
          >
            Profile Info
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{ color: "gray" }}
          >
            {profileData.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            sx={{ color: "gray" }}
          >
            {profileData.emp_id}
          </Typography>
        </CardContent>
      </Card>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="left" open={isOpen} onClose={onClose} disableScrollLock={ true }>
        {list}
      </Drawer>
    </div>
  );
}
