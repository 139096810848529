// import { createSlice } from "@reduxjs/toolkit";
// import { getAuthToken } from "../utils/auth";
// import { showSnackbar } from "./uiSlice";

// const initialState = {
//   territoryData: [],
//   status: false,
//   code: null,
//   message: {
//     displayMessage: "",
//     errorMessage: "",
//     traceID: null,
//   },
// };

// const locationSlice = createSlice({
//   name: "location",
//   initialState,
//   reducers: {
//     setTerritoryData: (state, action) => {
//       const { data, status, code, message } = action.payload;

//       state.territoryData = data;
//       state.status = status;
//       state.code = code;
//       state.message = message;
//     },
//   },
// });

// export const fetchTerritory = () => {
//   return async (dispatch) => {
//     const token = getAuthToken();

//     try {
//       const response = await fetch(
//         "https://dev.backend.mobigarage.com/v1/dgtemp/qc/locations",
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         dispatch(
//           showSnackbar({
//             message: "Internal Server Error",
//             severity: "warning",
//           })
//         );

//         throw new Error("Failed to Fetch Data");
//       }

//       const data = await response.json();
//       dispatch(setTerritoryData(data));
//       dispatch(
//         showSnackbar({
//           message: "reports loaded..",
//           severity: "success",
//         })
//       );

//       return data;
//     } catch (error) {
//       dispatch(
//         setTerritoryData({
//           data: [],
//           status: false,
//           code: null,
//           message: {
//             displayMessage: "",
//             errorMessage: error.message,
//             traceID: null,
//           },
//         })
//       );
//       dispatch(
//         showSnackbar({
//           message: "Internal Server Error",
//           severity: "warning",
//         })
//       );
//       return error; // Return the error
//     }
//   };
// };

// export const { setTerritoryData } = locationSlice.actions;
// export default locationSlice.reducer;


import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "../utils/auth";
import { showSnackbar } from "./uiSlice";

const initialState = {
  territoryData: [],
  status: false,
  code: null,
  message: {
    displayMessage: "",
    errorMessage: "",
    traceID: null,
  },
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setTerritoryData: (state, action) => {
      const { data, status, code, message } = action.payload;

      state.territoryData = data;
      state.status = status;
      state.code = code;
      state.message = message;
    },
  },
});

export const fetchTerritory = (type) => {
  return async (dispatch) => {
    const token = getAuthToken();
    let apiUrl;

    if (type === "phones") {
      apiUrl = "https://dev.backend.mobigarage.com/v1/dgtemp/qc/locations";
    } else if (type === "spares") {
      // Replace this with the URL for the spares API
      apiUrl = "https://dev.backend.mobigarage.com/v1/dgtemp/spare/locations";
    }

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        dispatch(
          showSnackbar({
            message: "Internal Server Error",
            severity: "warning",
          })
        );

        throw new Error("Failed to Fetch Data");
      }

      const data = await response.json();
      dispatch(setTerritoryData(data));
      dispatch(
        showSnackbar({
          message: "Reports loaded..",
          severity: "success",
        })
      );

      return data;
    } catch (error) {
      dispatch(
        setTerritoryData({
          data: [],
          status: false,
          code: null,
          message: {
            displayMessage: "",
            errorMessage: error.message,
            traceID: null,
          },
        })
      );
      dispatch(
        showSnackbar({
          message: "Internal Server Error",
          severity: "warning",
        })
      );
      return error; // Return the error
    }
  };
};

export const { setTerritoryData } = locationSlice.actions;
export default locationSlice.reducer;
