import * as React from "react";
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { hideSnackbar } from "../../store/uiSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MuiSnackBar = () => {
  const { isVisible, message, severity } = useSelector(state => state.snackBar);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideSnackbar()); 
  };

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{width: '80%'}}
    >
      <Alert severity={severity} sx={{ width: "60%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
