import React from "react";
import { motion } from "framer-motion";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export const SpareParts = () => {
  return (
    <motion.div
      style={{
        width: "24px", // Adjust the width and height based on your icon size
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
    >
      <SettingsOutlinedIcon style={{ width: "100%", height: "100%" }} />
    </motion.div>
  );
};
