// productInfoSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requests: [],
  end_date: "",
  brands: [],
  models: [],
  configs: [],
  loading: false,
  error: null,
};

const productInfoSlice = createSlice({
  name: "productInfo",
  initialState,
  reducers: {
    setProductInfoStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setProductInfoSuccess: (state, action) => {
      const { requests, end_date } = action.payload.data;
      state.requests = requests;
      state.end_date = end_date;
      state.brands = Array.from(new Set(requests.map((request) => request.brand)));
      state.models = Array.from(new Set(requests.map((request) => request.model)));
      state.configs = Array.from(new Set(requests.map((request) => request.config)));
      state.loading = false;
    },
    setProductInfoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setProductInfoStart,
  setProductInfoSuccess,
  setProductInfoFailure,
} = productInfoSlice.actions;
export default productInfoSlice.reducer;
