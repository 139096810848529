import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "../utils/auth";

const initialState = {
  profileData: {},
  pages: [],
  status: false,
  code: null,
  message: {
    displayMessage: "",
    errorMessage: "",
    traceID: null,
  },
};

const profileDataSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      const { data, status, code, message } = action.payload;
      state.profileData = data.profile_data;
      state.pages = data.pages;
      state.status = status;
      state.code = code;
      state.message = message;
    },
  },
});

export const fetchProfileData = () => {
  return async (dispatch) => {
    const token = getAuthToken();
    if (!token) {
      // Handle the case when there is no token
      return;
    } else {
      try {
        const response = await fetch(
          "https://dev.backend.mobigarage.com/v1/dgtemp/user_details", // Replace with your API endpoint
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to Fetch Profile Data");
        }

        const data = await response.json();
        dispatch(setProfileData(data));
        return data;
      } catch (error) {
        dispatch(
          setProfileData({
            data: {
              profile_data: {},
              pages: [],
            },
            status: false,
            code: null,
            message: {
              displayMessage: "",
              errorMessage: error.message,
              traceID: null,
            },
          })
        );
        return error;
      }
    }
  };
};

export const { setProfileData } = profileDataSlice.actions;
export default profileDataSlice.reducer;
