import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import { MuiSnackBar } from "../components/UI/MuiSnackBar";

export const RootLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: 6,
      }}
    >
      <MuiSnackBar />
      {/* <Header /> */}
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
