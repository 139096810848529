export const countDown = (endDate) => {
  if (!endDate) {
    return "00:00:00:00"; // Return a default value if endDate is not available
  }

  const remainingSeconds = endDate % 60;
  const remainingMinutes = Math.floor((endDate % 3600) / 60);
  const remainingHours = Math.floor((endDate % 86400) / 3600);
  const remainingDays = Math.floor(endDate / 86400);

  const pad = (num) => (num < 10 ? "0" + num : num);

  return `${pad(remainingDays)}:${pad(remainingHours)}:${pad(
    remainingMinutes
  )}:${pad(remainingSeconds)}`;
};
