import { otpResend } from "../../config";
import { showSnackbar } from "../uiSlice";

export const resendOtpThunk = () => {
  return async (dispatch, getState) => {
    const mobile_no = localStorage.getItem('mobile_no');

    dispatch(
      showSnackbar({
        message: "Sending Request...",
      })
    );

    try {
      const response = await fetch(otpResend, {
        method: "POST",
        body: JSON.stringify({ mobile_no }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        let errorMessage = "OTP request failed";
        if (response.status === 404) {
          errorMessage = "Internal Server Error. Please try again later.";
        }

        dispatch(showSnackbar({ message: errorMessage, severity: "warning" }));
        return Promise.reject(errorMessage); // Reject the Promise on error
      }
      const data = await response.json();
      console.log(data);
      dispatch(
        showSnackbar({
          message: "OTP sent successfully.",
          severity: "success",
        })
      );
      console.log(mobile_no)
    } catch (error) {
      dispatch(showSnackbar({ message: "OTP Request Failed" }));
      return Promise.reject("Error in sending OTP");
    }
  };
};
