import { redirect, useNavigate } from "react-router-dom";
import { getAuthToken } from "../utils/auth";

export const checkMobileNumberLoader = () => {
  const mobileNumber = localStorage.getItem("mobile_no");
  if (!mobileNumber) {
    return redirect("/");
  }
  return null;
};

// export const checkMobileNumberLoader = () => {
//   return new Promise((resolve, reject) => {
//     const mobileNumber = localStorage.getItem("mobile_no");
//     if (!mobileNumber) {
//       reject("No mobile number found"); // Or you can redirect using `redirect("/")`
//     } else {
//       resolve("Mobile number found");
//     }
//   });
// };

// export const checkAuthLoader = () => {
//   return new Promise((resolve, reject) => {
//     const token = localStorage.getItem("auth_token");

//     if (!token) {
//       reject("Permission not granted!!!"); // No token found, reject the promise
//     } else {
//       // Additional checks can be performed here if needed
//       resolve("Authentication token found"); // Token found, resolve the promise
//     }
//   });

// };

export const checkAuthToken = () => {
  const token = getAuthToken();

  if (!token) {
    redirect('/')
  }
  return null;
};
