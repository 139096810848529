import * as React from "react";
import { Outlet } from "react-router-dom";

import { Box, CardMedia } from "@mui/material";

export default function Auth() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        minHeight: "100vh"
      }}
    >
      <CardMedia
        sx={{ width: 140, height: 60 }}
        image={require("../assets/logo.PNG")}
        title="green iguana"
      />
      <Outlet />
    </Box>
  );
}
