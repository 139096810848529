import { configureStore } from "@reduxjs/toolkit";
import snackBarReducer from "./uiSlice";
import requestNumberReducer from "./auth/requestNumberSlice";
import authenticationReducer from "./auth/authenticationSlice";
import locationReducer from "./locationSlice";
import dataTableReducer from "./dataTableSlice";
import profileDataReducer from "./profileDataSlice";
import productInfoReducer from "./productInfo/productInfoSlice"


const store = configureStore({
  reducer: {
    snackBar: snackBarReducer,
    requestNumber: requestNumberReducer,
    authentication: authenticationReducer,
    location: locationReducer,
    dataTable: dataTableReducer,
    profileData: profileDataReducer,
    productInfo: productInfoReducer,
  },
});

export default store;
