import React, { useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataTable } from "../../store/dataTableSlice";
import { showSnackbar } from "../../store/uiSlice";

const MuiTable = ({ onRowClick }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dataTable.data);
  const queryClient = new QueryClient();
  const type = "spares"

  useEffect(() => {
    dispatch(fetchDataTable(type));
  }, [dispatch]);

  const modifyData = (data) => {
    return data.map((item) => {
      const { start_date, end_date, total, done, ...rest } = item;
      const start_seconds = start_date;
      const end_seconds = end_date;

      const formatStartDate = new Date(start_date * 1000).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      const formatEndDate = new Date(end_date * 1000).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      const duration = `${formatStartDate} - ${formatEndDate}`;

      // Calculate status as a fraction
      const status = `${done}/${total}`;

      return {
        ...rest,
        start_date: formatStartDate,
        end_date: formatEndDate,
        duration: duration,
        status: status,
        start_seconds,
        end_seconds,
      };
    });
  };

  const handleRowClick = (row) => {
    const currentDateInSeconds = Math.floor(Date.now() / 1000);
    const start_seconds = row.original.start_seconds;
    console.log(start_seconds);

    if (start_seconds >= currentDateInSeconds) {
      onRowClick(row.original.id === null);
      dispatch(
        showSnackbar({
          message: "Access Denied  ",
          severity: "warning",
        })
      );
      return;
    } else {
      row.getToggleSelectedHandler();
      onRowClick(row.original.id);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "duration",
        header: "Duration",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "start_seconds",
        header: "start_seconds",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "end_seconds",
        header: "end_seconds",
        enableEditing: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "left",
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: modifyData(data || []),
    enableColumnActions: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableStickyHeader: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    positionToolbarAlertBanner: "top",
    enableEditing: false,
    enableMultiRowSelection: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,

    initialState: {
      columnVisibility: {
        id: false,
        start_seconds: false,
        end_seconds: false,
      },
    },
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MaterialReactTable table={table} />
    </QueryClientProvider>
  );
};

export default MuiTable;
