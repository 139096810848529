import { createSlice } from "@reduxjs/toolkit";
import { showSnackbar } from "../uiSlice";
import { otpVerify } from "../../config";
import { fetchProfileData } from "../profileDataSlice";

const initialState = {
  authData: {
    authToken: localStorage.getItem("auth_token") || "", //
    expiryTimestamp: localStorage.getItem("expiry_timestamp") || 0,
    sessionId: 0,
  },
  code: "",
  status: "",
  message: "",
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuthData(state, action) {
      const { data, status, code, message } = action.payload;
      state.authData = {
        authToken: data.auth_token,
        expiryTimestamp: data.expiry_timestamp,
        sessionId: data.session_id,
      };
      state.status = status;
      state.message = message.displayMessage;
      state.code = code;
    },
    clearAuthData(state) {
      state.authData = {
        authToken: "",
        expiryTimestamp: 0,
        sessionId: 0,
      };
      state.status = "";
      state.message = "";
      state.code = "";
    },
  },
});

export const authenticationThunk = (mobile_no, otp) => {
  return async (dispatch) => {
    dispatch(
      showSnackbar({
        message: "Verifying OTP...",
      })
    );
    try {
      const response = await fetch(otpVerify, {
        method: "POST",
        body: JSON.stringify({ mobile_no, otp }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        dispatch(
          showSnackbar({
            message: "Internal Server Error",
            severity: "warning",
          })
        );
        // error handling code
      } else {
        const resData = await response.json();
        localStorage.setItem("auth_token", resData.data.auth_token);
        localStorage.setItem("expiry_timestamp", resData.data.expiry_timestamp);

        dispatch(authenticationSlice.actions.setAuthData(resData));
        dispatch(
          showSnackbar({
            message: resData.message.displayMessage || "User Verified.",
            severity: "success",
          })
        );
        dispatch(fetchProfileData());
        const timeToExpiration = resData.data.expiry_timestamp - Date.now();
        if (timeToExpiration > 0) {
          setTimeout(() => {
            dispatch(authenticationSlice.actions.clearAuthData());
            localStorage.removeItem("auth_token");
            localStorage.removeItem("mobile_no");
            localStorage.removeItem("expiry_timestamp");
            dispatch(
              showSnackbar({
                message: "Session expired. Please log in again.",
                severity: "info",
              })
            );
          }, timeToExpiration);
        } else {
          console.log(
            "Invalid expiration time. Unable to set automatic logout."
          );
        }
        return resData;
      }
    } catch (error) {
      // error handling code
    }
  };
};

export const { setAuthData, clearAuthData } = authenticationSlice.actions;

export default authenticationSlice.reducer;
