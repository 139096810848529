import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { numberVerificationThunk } from "../store/auth/requestNumberSlice";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";

export default function RequestOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobile_no: "",
    },
  });

  const handleNumericInput = (event) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = value.replace(/\D/g, "");
    }
  };

  const onSubmit = (data) => {
    dispatch(numberVerificationThunk(data.mobile_no))
      .then(() => {
        navigate("authentication");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const requiredLength = 10;
  const watchMobileNo = watch("mobile_no");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            rowGap: 2,
          }}
        >
          <TextField
            id="mobile_no"
            label="Mobile Number"
            type="number"
            variant="outlined"
            {...register("mobile_no", {
              required: "Mobile Number is required",
              minLength: {
                value: requiredLength,
                message: `Mobile Number should be ${requiredLength} digits`,
              },
              maxLength: {
                value: requiredLength,
                message: `Mobile Number should be ${requiredLength} digits`,
              },
              pattern: {
                value: /^\d+$/,
                message: "Please enter only numbers",
              },
            })}
            error={!!errors.mobile_no}
            helperText={errors.mobile_no?.message}
            onInput={handleNumericInput}
          />

          <Button
            variant="contained"
            sx={{ width: "120px" }}
            type="submit"
            disabled={watchMobileNo?.length !== requiredLength}
          >
            Send Otp
          </Button>
        </Box>
      </form>
    </Box>
  );
}
