import { useQuery } from "@tanstack/react-query";
import { getAuthToken } from "../../utils/auth";

function useGetConfigByBrand(brand) {
  const token = getAuthToken();

  return useQuery({
    queryKey: ["configs",brand],
    queryFn: async () => {
      const brandQueryParam = brand ? `&brand=${brand.join(",")}` : "";
      const response = await fetch(
        `https://dev.backend.mobigarage.com/v1/dgtemp/qc/qc_config_list?${brandQueryParam}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to Fetch Data");
      }

      const responseData = await response.json();
      const { data } = responseData;

      return {
        data,
      };
    },
    refetchOnWindowFocus: false,
  });
}

export default useGetConfigByBrand;
