import { getAuthToken } from "../utils/auth";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  status: false,
  code: null,
  message: {
    displayMessage: "",
    errorMessage: "",
    traceID: null,
  },
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      const { data, status, code, message } = action.payload;
      state.data = data;
      state.status = status;
      state.code = code;
      state.message = message;
    },
    // Additional reducers can be added for updating specific parts of the state if needed
  },
});

export const fetchDataTable = (type) => {
  return async (dispatch) => {
    const token = getAuthToken();
    let apiUrl;

    if (type === "phones") {
      apiUrl = "https://dev.backend.mobigarage.com/v1/dgtemp/qc/qc_requests_list";
    } else if (type === "spares") {
      apiUrl = "https://dev.backend.mobigarage.com/v1/dgtemp/spare/spare_requests_list";
      // Replace with the actual URL for the spares API
    }

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to Fetch Data");
      }

      const data = await response.json();
      dispatch(setDataTable(data));

      return data;
    } catch (error) {
      dispatch(
        setDataTable({
          data: [],
          status: false,
          code: null,
          message: {
            displayMessage: "",
            errorMessage: error.message,
            traceID: null,
          },
        })
      );
      return error; // Return the error
    }
  };
};

export const { setDataTable } = dataTableSlice.actions;
export default dataTableSlice.reducer;
