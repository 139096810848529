import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./pages/Auth";
import { RootLayout } from "./pages/RootLayout";
import RequestOtp from "./pages/RequestOtp";
import { VerifyOtp } from "./pages/VerifyOtp";
import {Home} from "./pages/home/Home";
import { MuiSnackBar } from "./components/UI/MuiSnackBar";
import "./App.css";


import { checkAuthToken } from "./loaders/loaders";


import { Homepage } from "./pages/home/Homepage";
import Products from "./pages/phones/Products";
import { DashBoard } from "./pages/phones/DashBoard";
import {  DashBoardSpares } from "./pages/spares/DashBoardSpares";
import ProductsSpares from "./pages/spares/ProductsSpares";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Auth />,
        children: [
          { path: "/", element: <RequestOtp /> },
          {
            path: "authentication",
            element: <VerifyOtp />,
          },
        ],
      },
      {
        path: "/home",
        element: <Home />,
        loader: checkAuthToken(),

        children: [
          {
            path: "/home",
            element: <Homepage />,
          },
          {
            path: "/home/phones/:city_id",
            element: <DashBoard />,
          },
          {
            path: "/home/phones/:city_id/:request_id",
            element: <Products />
            // element: <ProductsTable />,
          },
          {
            path: "/home/spares/:city_id",
            element: <DashBoardSpares />,
          },
          {
            path: "/home/spares/:city_id/:request_id",
            element: <ProductsSpares />
            // element: <ProductsTable />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <MuiSnackBar />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
