import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Fragment, useEffect, useState } from "react";
import { Typography, Link } from "@mui/material";
import useGetBrandList from "../../query/phonesQuery/useGetBrandLists";
import useGetModelByBrand from "../../query/phonesQuery/useGetModelByBrand";
import useGetConfigByBrand from "../../query/phonesQuery/useGetConfigByBrand";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Filters({ isOpen, onClose, onAppliedFilter }) {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState([]);

  const [showOption, setShowOption] = useState({
    brand: false,
    model: false,
    config: false,
  });

  const { data: brands } = useGetBrandList();
  const brandList = brands;
  const { data: modelsByBrand } = useGetModelByBrand(selectedBrands);
  const modelsOfBrands = modelsByBrand;

  const { data: configByBrand } = useGetConfigByBrand(selectedBrands);
  const configsOfBrand = configByBrand;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateUrl = (filterType, updatedValues) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);

      switch (filterType) {
        case "brand":
          newSearchParams.set("brand", updatedValues.join(","));
          break;
        case "model":
          newSearchParams.set("model", updatedValues.join(","));
          break;
        case "config":
          newSearchParams.set("config", updatedValues.join(","));
          break;
        // Add other cases for different filter types if needed
        default:
          break;
      }

      // Replace the current URL with the updated search params
      navigate(`?${newSearchParams.toString()}`, {
        replace: true,
      });

      return newSearchParams;
    });
  };

  const handleBrandToggle = (brand) => {
    setSelectedBrands((prevSelectedBrands) => {
      const updatedBrands = prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter((selectedBrand) => selectedBrand !== brand)
        : [...prevSelectedBrands, brand];

      updateUrl("brand", updatedBrands);

      console.log(updatedBrands);
      return updatedBrands;
    });
  };
  const handleConfigToggle = (config) => {
    setSelectedConfig((prevSelectedConfigs) => {
      const updatedConfigs = prevSelectedConfigs.includes(config)
        ? prevSelectedConfigs.filter(
            (selectedConfig) => selectedConfig !== config
          )
        : [...prevSelectedConfigs, config];
      updateUrl("config", updatedConfigs);

      console.log(updatedConfigs);
      return updatedConfigs;
    });
  };

  const handleModelToggle = (model) => {
    setSelectedModels((prevSelectedModels) => {
      const updatedModels = prevSelectedModels.includes(model)
        ? prevSelectedModels.filter((selectedModel) => selectedModel !== model)
        : [...prevSelectedModels, model];
      updateUrl("model", updatedModels);

      console.log(updatedModels);
      return updatedModels;
    });
  };

  const handleShowOptionChange = (category) => {
    setShowOption((prevShowOptions) => ({
      brand: category === "brand" ? !prevShowOptions.brand : false,
      model: category === "model" ? !prevShowOptions.model : false,
      config: category === "config" ? !prevShowOptions.config : false,
    }));
    console.log(showOption);
  };
  useEffect(() => {
    setSelectedBrands(
      (searchParams.get("brand") || "").split(",").filter(Boolean)
    );
    setSelectedModels(
      (searchParams.get("model") || "").split(",").filter(Boolean)
    );
    setSelectedConfig(
      (searchParams.get("config") || "").split(",").filter(Boolean)
    );
  }, [searchParams]);

  const handleApplyFilters = () => {
    // Pass selected brands to the parent component
    onAppliedFilter();
    onClose(false);
  };

  const handleClearAll = () => {
    setSelectedBrands([]);
    setSelectedModels([]);
    setSelectedConfig([]);

    // Clear the URL parameters
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete("brand");
      newSearchParams.delete("model");
      newSearchParams.delete("config");
      navigate(`?${newSearchParams.toString()}`, {
        replace: true,
      });

      return newSearchParams;
    });

    onClose(false);
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => onClose(false)}
      disableScrollLock={true}
      PaperProps={{
        sx: {
          width: {
            xs: "70%",
            sm: "50%", // Add other sizes as needed
          },
          display: "flex",
          flexDirection: "row",
          pr: 1,
        },
      }}
    >
      <Box
        sx={{
          width: "100px",
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #ccc",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
        role="presentation"
        onKeyDown={() => onClose(false)}
      >
        <Box>
          <Typography variant="h6" component="h6" sx={{ p: 1 }}>
            Filters
          </Typography>
        </Box>

        <Divider />

        <List sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
          <Fragment>
            <ListItem
              disablePadding
              sx={{
                size: "small",
              }}
            >
              <ListItemButton onClick={() => handleShowOptionChange("brand")}>
                <ListItemText primary="Brand" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                size: "small",
              }}
            >
              <ListItemButton onClick={() => handleShowOptionChange("model")}>
                <ListItemText primary="Model" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                size: "small",
              }}
            >
              <ListItemButton onClick={() => handleShowOptionChange("config")}>
                <ListItemText primary="Config" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            alignItems: "center",
            columnGap: 2,
          }}
        >
          <Link
            sx={{ cursor: "pointer", m: 1.9 }}
            underline="none"
            onClick={handleApplyFilters}
          >
            Apply
          </Link>
          <Link
            variant="contained"
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={handleClearAll}
          >
            Clear
          </Link>
        </Box>
        <Divider />

        <List sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
          {showOption.brand && (
            <Fragment>
              {brandList?.data?.map((brandOption) => (
                <ListItem
                  key={brandOption.id}
                  disablePadding
                  onClick={() => handleBrandToggle(brandOption.label)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedBrands.includes(brandOption.label)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={brandOption.label} />
                </ListItem>
              ))}
            </Fragment>
          )}
          {showOption.model && (
            <Fragment>
              {modelsOfBrands?.data?.map((modelOption) => (
                <ListItem
                  key={modelOption.id}
                  disablePadding
                  onClick={() => handleModelToggle(modelOption.label)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedModels.includes(modelOption.label)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={modelOption.label} />
                </ListItem>
              ))}
            </Fragment>
          )}
          {showOption.config && (
            <Fragment>
              {configsOfBrand?.data?.map((configOption) => (
                <ListItem
                  key={configOption.id}
                  disablePadding
                  onClick={() => handleConfigToggle(configOption.label)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedConfig.includes(configOption.label)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={configOption.label} />
                </ListItem>
              ))}
            </Fragment>
          )}
        </List>
      </Box>
    </Drawer>
  );
}
