import { redirect } from "react-router-dom";

export const authData = (data) => {
  localStorage.setItem("auth_token", data.auth_token);
  localStorage.setItem("expiry_timestamp", data.expiry_timestamp);
  localStorage.setItem("session_id", data.session_id);
};

export const getAuthToken = () => {
  const token = localStorage.getItem("auth_token");
  return token;
};

