import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisible: false,
  message: "",
  severity: "info",
};

const snackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar(state, action) {
      state.isVisible = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || "info";
    },
    hideSnackbar(state) {
      state.isVisible = false;
      state.message = "";
      state.severity = "info";
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
